
import { Vue, Component, Watch, Provide } from 'vue-property-decorator'
import BidCardCarousel from '@/components/BidCardCarousel.vue'
import BillingSummary from '@/components/BillingSummary.vue'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import ProviderInfo from '@/components/ProviderInfo.vue'
import ProviderInfoSkeleton from '@/components/ProviderInfoSkeleton.vue'
import ProviderMobileFooter from '@/components/ProviderMobileFooter.vue'
import StickyColumnLayout from '@/layouts/StickyColumnLayout.vue'
import QuoteCard from '@/components/QuoteCard.vue'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import DownloadQuoteButton from '@/components/DownloadQuoteButton.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import support from '@/store/modules/support'
import operator from '@/services/operator'
import quotes from '@/services/quotes'
import { PartnerTypeId } from '@/utils/enum'
import {
  CheckoutDetailResult,
  OperatorDetail,
  QuoteDetailV2,
} from '@/models/dto'
import auth from '@/store/modules/auth'
import { isQuoteActive } from '@/utils/quote'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import customerAccount from '@/services/customerAccount'

@Component({
  components: {
    QuoteCard,
    BillingSummary,
    NotFoundLayout,
    StickyColumnLayout,
    ProviderInfo,
    ProviderMobileFooter,
    BidCardCarousel,
    ProviderInfoSkeleton,
    ShareQuoteDialog,
    DownloadQuoteButton,
    SkeletonTripCollectionCard,
  },
})
export default class Provider extends Vue {
  @Provide('isInProvider') isInProvider = true
  providerDetail: OperatorDetail = null
  checkoutDetail: CheckoutDetailResult = null
  quote: QuoteDetailV2 = null
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet | null = null
  notFound = false
  refreshInterval: any = null
  isModePreview = false
  loaded = false
  support = support

  @Watch('$route', { immediate: true })
  async routeChanged(): Promise<void> {
    if (this.$route.name === 'provider-detail-preview') {
      this.isModePreview = true
    }
    await this.loadData()
    await this.refreshData()
  }

  get isBronze(): boolean {
    return this.providerDetail?.company?.partnerTypeId === PartnerTypeId.Bronze
  }

  get quoteHash(): string | undefined {
    return this.checkoutDetail?.quote?.hash
  }

  get companyId(): number {
    return parseInt(this.$route.params.providerId)
  }

  get showShareQuoteDialog(): boolean {
    if (!this.loaded || !this.quote) {
      return false
    }
    return this.isUserQuoteCustomer || this.isUserInSameOrganization
  }

  get isUserQuoteCustomer(): boolean {
    const quoteCustomerId = this.quote.trips?.[0]?.customerId
    return quoteCustomerId === auth.user?.userId
  }

  get isUserInSameOrganization(): boolean {
    const quoteCustomerCustomerAccountId = this.quote?.customer
      ?.customerAccountId
    if (!quoteCustomerCustomerAccountId) {
      return false
    }
    return (
      quoteCustomerCustomerAccountId ===
        auth.customerAccount.customerAccountId ||
      auth.childCustomerAccountIds.includes(quoteCustomerCustomerAccountId)
    )
  }

  get isElite(): boolean {
    return !!this.quote?.isElite
  }

  get isSelfServe(): boolean {
    return this.quote?.isSelfServe === true
  }


  mounted(): void {
    if (!this.isModePreview) {
      this.refreshInterval = setInterval(() => this.refreshData(), 10000)
    }
  }

  beforeDestroy(): void {
    clearInterval(this.refreshInterval)
  }

  async loadData(): Promise<void> {
    const { quoteId } = this.$route.params
    const { providerId } = this.$route.params
    const { marketId } = this.$route.params
    if (this.isModePreview) {
      this.getProviderPreview(providerId, marketId)
    } else {
      await this.getCheckoutDetail(providerId, quoteId)
      await this.getProviderDetails(quoteId, providerId)
      await this.getEffectiveDefaultValueSet()
    }
    this.loaded = true
  }

  async refreshData(): Promise<void> {
    const { quoteId } = this.$route.params
    const { providerId } = this.$route.params
    await this.getCheckoutDetail(providerId, quoteId)
    await this.getQuoteDetail()
    await this.getProviderDetails(quoteId, providerId, true)
  }

  async getProviderDetails(
    quoteId,
    providerId,
    isRefresh = false
  ): Promise<void> {
    try {
      const { data } = await operator.detailsByQuoteIdAndCompanyId(
        quoteId,
        providerId
      )
      this.providerDetail = data.providerDetail
    } catch (e) {
      if (!isRefresh) {
        this.notFound = true
      }
    }
  }

  async getProviderPreview(providerId, marketId): Promise<void> {
    try {
      const { data } = await operator.detailsPreviewByCompanyIdAndMarketId(
        providerId,
        marketId
      )
      this.providerDetail = data.providerDetail
    } catch (e) {
      this.notFound = true
    }
  }

  async getCheckoutDetail(providerId, quoteId): Promise<void> {
    try {
      let response
      if (providerId) {
        response = await quotes.checkoutDetail(quoteId, providerId)
      } else {
        response = await quotes.selfServeCheckoutDetail(quoteId)
      }
      const { data } = response

      if (!data || !isQuoteActive(data.quote)) {
        this.$router.push({ name: 'quote-index' })
        return
      }
      this.checkoutDetail = data
    } catch (err) {
      clearInterval(this.refreshInterval)
      this.$router.push({ name: 'quote-index' })
    }
  }

  async getQuoteDetail(): Promise<void> {
    const quoteId = parseInt(this.$route.params.quoteId) || null
    try {
      const response = await quotes.detailV2(quoteId)
      this.quote = response.data.data
    } catch (err) {
      clearInterval(this.refreshInterval)
      this.$router.push({ name: 'quote-index' })
    }
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    const { data } = await customerAccount.effectiveDefaultValueSet(
      auth.customerAccount.customerAccountId
    )
    this.effectiveDefaultValueSet = data
  }
}
