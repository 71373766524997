
import { Vue, Component } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import PaymentSentDialog from '@/components/PaymentSentDialog.vue'
import NoReservations from '@/components/NoReservations.vue'
import NoReservationsMobile from '@/components/NoReservationsMobile.vue'
import ReservationIndexMobileCard from '@/components/ReservationIndexMobileCard.vue'
import DateSelector from '@/components/DateSelector.vue'
import reservation from '@/services/reservation'
import { filter } from '@/utils/filter'
import { ReservationIdentifiers, TableViewReservation } from '@/models/dto'
import {
  currencyFilter,
  formatPickupDestinationTextFromAddresses,
} from '@/utils/string'
import { ActionColumn } from '@/models/ActionColumn'
import { SelectColumn } from '@/models/SelectColumn'
import { RawLocation } from 'vue-router'
import { DataTableColumn } from '@/models/DataTableColumn'
import { PaymentStatusLabel } from '@/utils/enum'
import { generateInvoiceLinkByHashes } from '@/utils/invoice'
import { removeFromArray } from '@/utils/array'
import { TableViewFilter } from '@/models/TableView'
import { v4 as uuidv4 } from 'uuid'
import auth from '@/store/modules/auth'
@Component({
  components: {
    Main,
    CUCollectionTable,
    DateSelector,
    PaymentSentDialog,
    NotFoundLayout,
    NoReservations,
    NoReservationsMobile,
    ReservationIndexMobileCard,
  },
})
export default class ReservationIndex extends Vue {
  filters: any = filter()
  tableView = reservation.tableView
  loading = true
  selectedReservations: TableViewReservation[] = []
  paymentSentDialogIsOpen = false
  date = null
  uuid = uuidv4()

  get initialFilters(): TableViewFilter[] {
    return [
      {
        column: {
          _t_id: '9462a08e-b91b-4198-894a-3677678bdc4d',
          value: 'startDate',
          filterType: 'gte',
          text: '',
        },
        value: this.date,
      },
      {
        column: {
          _t_id: 'f136e8cf-dbb1-4cff-ad37-7dfedc909038',
          value: 'startDate',
          filterType: 'lte',
          text: '',
        },
        value: this.date,
      },
    ]
  }

  columns: DataTableColumn[] = [
    {
      _t_id: 'db63cd0a-60bf-44ec-9d41-6d5b0645bbab',
      elementId: 'select',
      text: 'Select',
      value: 'select',
      type: 'select',
    },
    {
      _t_id: '2b88e5e7-631c-4d3c-8c71-23efea6f965e',
      elementId: 'managed-id',
      text: this.$t('common.ID'),
      value: 'managedId',
    },
    {
      _t_id: '843f3e81-8325-466b-bc5c-ed831899f7b5',
      elementId: 'trip-status',
      text: this.$t('common.TRIP_STATUS'),
      value: 'reservationStatusLabel',
      computedText: (row: TableViewReservation): string =>
        row.reservationStatusLabel === 'On Hold'
          ? 'Upcoming'
          : row.reservationStatusLabel,
    },
    {
      _t_id: '261320ef-2e8b-4e5c-9581-ba8a7a756893',
      elementId: 'pickup-destination',
      text: this.$t('common.ROUTE'),
      value: 'firstStopAddress',
      computedText: (row: TableViewReservation): string => {
        if (row.tripRouteName) {
          return row.tripRouteName
        }

        const minOrderIndex = Math.min(
          ...row.stops.map((stop) => stop.orderIndex)
        )
        const nextOrderIndex = minOrderIndex + 1
        const pickup = row.stops.find(
          (stop) => stop.orderIndex === minOrderIndex
        )
        const dropoff = row.stops.find(
          (stop) => stop.orderIndex === nextOrderIndex
        )
        return formatPickupDestinationTextFromAddresses(
          pickup.address,
          dropoff.address,
          '>'
        )
      },
    },
    {
      _t_id: '877a7732-35b4-4b7a-8f5c-993dfee8e6da',
      elementId: 'trip-type',
      text: this.$t('common.TRIP_TYPE'),
      value: 'tripTypeLabel',
    },
    {
      _t_id: 'a43c7fa4-8f25-419b-a307-857802bdb81f',
      elementId: 'pickup-date',
      text: this.$t('common.PICKUP_DATE'),
      value: 'startDate',
      computedText: (row: TableViewReservation): string =>
        this.$dayjs(row.startDate)
          .tz(row.firstStopAddressTimeZone)
          .format('MM/DD/YYYY'),
    },
    {
      _t_id: 'fd6382e8-fa09-4251-942f-226a1976a09a',
      elementId: 'pickup-time',
      text: this.$t('common.PICKUP_TIME'),
      value: 'startDate',
      computedText: (row: TableViewReservation): string =>
        this.$dayjs(row.startDate)
          .tz(row.firstStopAddressTimeZone)
          .format('h:mm A'),
    },
    {
      _t_id: 'cc4ee27c-35b0-4685-abb1-209846f3ccc2',
      elementId: 'price',
      text: this.$t('common.PRICE'),
      value: 'customerTotal',
      computedText: (row: TableViewReservation): string =>
        currencyFilter(row.customerTotal),
    },
    {
      _t_id: 'a0ac3e93-37ec-442b-a49d-4f9bcca8fdce',
      elementId: 'payment-status',
      text: this.$t('reservationsTable.PAYMENT_STATUS'),
      value: 'paymentStatus',
    },
    {
      _t_id: '6fd01e5f-5c00-4b13-81b1-9acc12c7eb12',
      elementId: 'booking-contact',
      text: this.$t('reservationsTable.BOOKING_CONTACT'),
      value: 'customerName',
      hidden: this.hideBookingContactColumn,
      computedText: (row: TableViewReservation): string => {
        return `${row.customerFirstName} ${row.customerLastName}`
      },
    },
    {
      _t_id: '66051be6-43cb-4d1d-babc-67a4f78ab263',
      elementId: 'team',
      text: this.$t('reservationsTable.TEAM'),
      value: 'customerAccountName',
      hidden: this.hideTeamColumn,
    },
    {
      _t_id: '5c0b45d6-3887-45e1-9be8-48b0e81d12eb',
      elementId: 'actions',
      text: '',
      value: 'actions',
      type: 'actions',
    },
  ]

  selectColumn: SelectColumn = {
    _t_id: 'db63cd0a-60bf-44ec-9d41-6d5b0645bbab',
    elementId: 'select',
    key: 'select',
    color: 'primary',
    predefinedSelects: [
      {
        label: 'All',
        filter: (item: TableViewReservation) => true,
      },
      {
        label: 'None',
        filter: (item: TableViewReservation) => false,
      },
      {
        label: PaymentStatusLabel.FullyPaid,
        filter: (item: TableViewReservation) =>
          item.paymentStatus === PaymentStatusLabel.FullyPaid,
      },
      {
        label: PaymentStatusLabel.NotPaid,
        filter: (item: TableViewReservation) =>
          item.paymentStatus === PaymentStatusLabel.NotPaid,
      },
      {
        label: PaymentStatusLabel.PartiallyPaid,
        filter: (item: TableViewReservation) =>
          item.paymentStatus === PaymentStatusLabel.PartiallyPaid,
      },
    ],
    actions: [
      {
        displayText: 'Generate Invoice',
        key: 'generate-invoice',
        color: 'primary',
        action: () => this.generateInvoice(),
        isSingleSelectOnly: false,
        isMultiSelectOnly: false,
        icon: 'request_quote',
      },
      {
        displayText: 'Sent Your Payment?',
        key: 'sent-your-payment',
        color: 'primary',
        action: () => this.markPaymentSent(),
        isSingleSelectOnly: false,
        isMultiSelectOnly: false,
        icon: 'account_balance',
      },
    ],
  }

  actions: ActionColumn[] = [
    {
      displayText: 'Details',
      key: 'details',
      color: 'primary',
      icon: '',
      confirmModal: false,
      ariaLabel: 'View Reservation Details',
      isDetail: true,
      detailRoute: (row: TableViewReservation): RawLocation =>
        this.getDetailRoute(row),
    },
  ]

  get selectedReservationIdentifiers(): ReservationIdentifiers[] {
    return this.selectedReservations.map((reservation) => ({
      reservationId: reservation.reservationId,
      managedId: reservation.managedId,
    }))
  }

  get noSubAccounts(): boolean {
    return auth.customerSubAccounts.length === 0
  }

  get hideBookingContactColumn(): boolean {
    const noOtherUsersInAccount = auth?.customerAccount?.customers?.length <= 1
    return this.noSubAccounts && noOtherUsersInAccount
  }

  get hideTeamColumn(): boolean {
    return this.noSubAccounts
  }

  postFirstLoadHandler(items: TableViewReservation[]): void {
    const isOnlyOneReservation = items?.length === 1
    if (!isOnlyOneReservation) {
      return
    }
    const reservation = items[0]
    this.$router.push(this.getDetailRoute(reservation))
  }

  handleClickRow(row: TableViewReservation): void {
    this.$router.push(this.getDetailRoute(row))
  }

  getDetailRoute(row: TableViewReservation): RawLocation {
    return {
      name: 'reservation-detail',
      params: { id: row.reservationId.toString() },
    }
  }

  selectReservations(reservations: TableViewReservation[]): void {
    this.selectedReservations.push(...reservations)
  }

  deselectReservation(reservation: TableViewReservation): void {
    this.selectedReservations = removeFromArray(
      this.selectedReservations,
      reservation
    )
  }

  clearSelectedReservations(): void {
    this.selectedReservations = []
  }

  generateInvoice(): void {
    const hashesList = this.selectedReservations.map((res) => res.hash)
    if (hashesList.length > 0) {
      const hashes = `${hashesList.join(',')},`
      const url = generateInvoiceLinkByHashes(hashes)
      window.open(url, '_blank')
    }
  }

  markPaymentSent(): void {
    this.paymentSentDialogIsOpen =
      this.selectedReservationIdentifiers.length > 0
  }
}
