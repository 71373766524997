import { OperatorDetailResult } from '@/models/dto'
import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves operator details for a given quote ID and company ID.
   * @param quoteId - The quote ID to retrieve operator details for.
   * @param companyId - The company ID to retrieve operator details for.
   * @returns A promise that resolves to the operator details for the specified quote ID and company ID.
   */
  detailsByQuoteIdAndCompanyId(
    quoteId: number,
    companyId: number
  ): Promise<AxiosResponse<OperatorDetailResult>> {
    const url = `https://${baseUrl()}/providerDetail/${quoteId}/${companyId}`
    return httpService.get(url)
  },
  /**
   * Retrieves a preview of operator details for a given company ID and market ID.
   * @param companyId - The company ID to retrieve operator details for.
   * @param marketId - The market ID to retrieve operator details for.
   * @returns A promise that resolves to the operator details preview for the specified company ID and market ID.
   */
  detailsPreviewByCompanyIdAndMarketId(
    companyId: number,
    marketId: number
  ): Promise<AxiosResponse<OperatorDetailResult>> {
    const url = `https://${baseUrl()}/providerDetail/preview/${companyId}/${marketId}`
    return httpService.get(url)
  },
}
